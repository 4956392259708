module.exports = {
    hosts: [
        'localhost',
        '*.dev.efonds.ag',
        'fdb-mzm-ui.efonds.com', // PREPROD alpha
        'mzm2.efonds.com', // PREPROD beta
        'fdb.efonds.com', // PREPROD final config
        '*.preprod.efonds.ag',
        '*.prod.efonds.ag',
        'handel.zweitmarkt.de'
    ],
    title: 'Geschlossene Fonds kaufen und verkaufen | Fondsbörse Deutschland',
    name: 'Fondsbörse Deutschland',
    favicon: 'favicon-meinzweitmarkt.png',
}
